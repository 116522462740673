<template>
  <div>
    <page-title :heading="$t('accounting.lang_nav_invoiceDraft')" :subheading="$t('accounting.lang_nav_invoiceDraft')" :icon=icon ></page-title>

    <div class="app-main__inner">
      <DraftsComponent/>
    </div>
  </div>
</template>

<script>
import PageTitle from "@/Layout/Components/PageTitle";
import DraftsComponent from "@/components/billing/drafts/DraftsComponent";

export default {
  name: "Drafts",
  components: {DraftsComponent, PageTitle},
  data: () => ({
    icon: 'pe-7s-note2 icon-gradient bg-tempting-azure',
  })
}
</script>

<style scoped>

</style>